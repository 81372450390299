<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mt-2 mb-3">
      <b-col
          lg="12"
      >
        <h2 style="text-align: center">{{ title }}</h2>
      </b-col>
    </b-row>
    <b-form @submit.prevent="updateQuestion()">
      <b-row>
        <b-col cols="12" class="mb-1">
          <div align="right">
            <b-form-group
                label="Nombre completo"
                label-for="h-number"
            >
              <flat-pickr
                  v-model="date"
                  style="width: 110px"
                  class="form-control"
                  disabled
                  :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6" class="mb-1">
          <b-form-group
              label="Nombre completo"
              label-for="h-number"
          >
            <b-form-input
                v-model="data_patient.name"
                placeholder="Nombre completo"
                disabled=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mb-1">
          <b-form-group
              label="Años"
              label-for="h-number"
          >
            <b-form-input
                v-model="years"
                placeholder="Años"
                disabled=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mb-1">
          <b-form-group
              label="Fecha de nacimiento"
              label-for="h-number"
          >
            <b-form-input
                v-model="data_patient.birthday"
                placeholder="Nombre completo"
                disabled=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mb-1">
          <b-form-group
              label="Tipo de sangre"
              label-for="h-number"
          >
            <b-form-input
                v-model="questionnaire.blood_type"
                placeholder="Tipo de sangre"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mb-1">
          <b-form-group
              label="Desconozco"
              label-for="h-number"
          >
            <b-form-input
                v-model="questionnaire.unknown_type"
                placeholder="Desconozco"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mb-1">
          <b-form-group
              label="Celular"
              label-for="h-number"
          >
            <b-form-input
                v-model="data_patient.mobile"
                placeholder="Celular"
                disabled=""
            />
          </b-form-group>
        </b-col>
        <b-col
            lg="3" cols="5" class="mt-2"
        >
          <h4>Alergias:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.allergies"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.allergies"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col lg="9" cols="12">
          <b-row v-if="questionnaire.allergies">
            <b-col lg="6" cols="6">
              <label for="textarea-default">¿A qué?</label>
              <b-form-textarea
                  v-model="questionnaire.type_allergies"
                  id="textarea-default"
                  placeholder="¿A qué?"
                  rows="3"
              />
            </b-col>
            <b-col lg="6" cols="6">
              <label for="textarea-default">¿Qué la ocasionan?</label>
              <b-form-textarea
                  v-model="questionnaire.causes_allergies"
                  id="textarea-default"
                  placeholder="¿Qué la ocasionan?"
                  rows="3"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="mt-2">
          <label for="textarea-default">Motivo de visita</label>
          <b-form-textarea
              v-model="questionnaire.reason_visit"
              id="textarea-default"
              placeholder="Motivo de visita"
              rows="3"
          />
        </b-col>
        <b-col
            lg="3" cols="5" class="mt-2"
        >
          <h4>¿ Acude usted con algún síntoma/moletia?:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.bothersome_symptom"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.bothersome_symptom"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-row v-if="questionnaire.bothersome_symptom">
            <b-col cols="12">
              <label for="textarea-default">¿Cuál?</label>
              <b-form-textarea
                  v-model="questionnaire.which_symptom"
                  id="textarea-default"
                  placeholder="¿Cuál?"
                  rows="3"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
            lg="3" cols="5" class="mt-2"
        >
          <h4>¿ Ha tenido fiebre en los últimos 5 dias?:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.fever"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.fever"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-row v-if="questionnaire.fever">
            <b-col cols="12">
              <b-form-group
                  label="Fecha de inicio de fiebre"
                  label-for="h-number"
              >
                <flat-pickr
                    v-model="questionnaire.date_fever"
                    class="form-control"
                    :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
            lg="3" cols="5" class="mt-2"
        >
          <h4>¿ Trae estudios con usted para su médico?:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.studies"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.studies"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-row v-if="questionnaire.studies">
            <b-col cols="12">
              <label for="textarea-default">¿Cuáles?</label>
              <b-form-textarea
                  v-model="questionnaire.which_studies"
                  id="textarea-default"
                  placeholder="¿Cuáles?"
                  rows="3"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="mt-2">
          <label for="textarea-default">¿Quién lo recomienda con nosotros?</label>
          <b-form-textarea
              v-model="questionnaire.recommendation_us"
              id="textarea-default"
              placeholder="¿Quién lo recomienda con nosotros?"
              rows="3"
          />
        </b-col>
        <b-col cols="12" class="mt-2">
          <h4>Contamos con un sistema de llenado automático para los documentos de las aseguradoras.</h4>
        </b-col>
        <b-col
            lg="3" cols="5" class="mt-2"
        >
          <h4>¿ Cuenta con seguro de Gastos Médicos mayores?:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.medical_insurance"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.medical_insurance"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-row v-if="questionnaire.medical_insurance">
            <b-col cols="12">
              <label for="textarea-default">¿Cuáles?</label>
              <b-form-textarea
                  v-model="questionnaire.which_medical_insurance"
                  id="textarea-default"
                  placeholder="¿Cuáles?"
                  rows="3"
              />
            </b-col>
          </b-row>

        </b-col>
        <b-col cols="12" class="mb-1">
          <b-form-group
              label="En caso de emergencia llamar a:"
              label-for="h-number"
          >
            <b-form-input
                v-model="questionnaire.emergency_name"
                placeholder="Nombre completo:"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" class="mb-1">
          <b-form-group
              label="Celular:"
              label-for="h-number"
          >
            <b-form-input
                v-model="questionnaire.phone_emergency"
                placeholder="Celular:"
                @keypress="isNumber($event)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12" class="mb-1">
          <b-form-group
              label="Parentesco con el paciente:"
              label-for="h-number"
          >
            <b-form-input
                v-model="questionnaire.relationship_patient"
                placeholder="Parentesco con el paciente:"
            />
          </b-form-group>
        </b-col>
        <b-col
            lg="3" cols="12" class="mt-2"
        >
          <h4>¿ Va a requerir factura o recibo por los servicios otorgados?:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.billing"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.billing"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col v-if="questionnaire.billing"
               lg="9" cols="12" class="mt-2"
        >
          <h5>En caso de que si, pase a recepción a compartir sus datos de facturación</h5>
        </b-col>

        <b-col
            cols="12" class="mt-4"
        >
          <h4>¿ Ha leido y firmado el aviso de privacidad de ONKIMIA?:
          </h4>
          <div style="display: flex; justify-content: space-around">
            <b-form-radio
                v-model="questionnaire.read_sign_notice"
                :value="true"
            >
              Si
            </b-form-radio>
            <b-form-radio
                v-model="questionnaire.read_sign_notice"
                :value="false"
            >
              No
            </b-form-radio>
          </div>
        </b-col>
        <b-col
            lg="12" cols="12" class="mt-4"
        >
          <h5>En caso de no contar con él solicitar uno con la recepción ¡Gracias por su preferencia!</h5> <br>
          <h5>En un momento le indicaremos cuándo pasar a su consulta/tratamiento</h5>
        </b-col>

        <b-col
            lg="12" cols="12" class="mt-4"
        >
          <h2>Signos Vitales</h2>
          <b-row>
            <b-col
                lg="3" cols="4" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="Fc"
                >
                  <b-form-input
                      v-model="questionnaire.fc"
                      placeholder="Fc"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">lpm</p>
              </div>
            </b-col>
            <b-col
                lg="3" cols="4" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="Fr"
                >
                  <b-form-input
                      v-model="questionnaire.fr"
                      placeholder="Fr"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">rpm</p>
              </div>
            </b-col>
            <b-col
                lg="3" cols="4" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="TA"
                >
                  <b-form-input
                      v-model="questionnaire.ta"
                      placeholder="TA"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">mmHg</p>
              </div>
            </b-col>
            <b-col
                lg="3" cols="6" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="Temp"
                >
                  <b-form-input
                      v-model="questionnaire.temperature"
                      placeholder="Temp"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">°C</p>
              </div>
            </b-col>
            <b-col
                lg="3" cols="6" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="SpO2%"
                >
                  <b-form-input
                      v-model="questionnaire.oxygenation"
                      placeholder="SpO2%"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">%</p>
              </div>
            </b-col>
            <b-col
                lg="3" cols="6" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="Peso"
                >
                  <b-form-input
                      v-model="questionnaire.weight"
                      placeholder="Peso"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">kg</p>
              </div>
            </b-col>
            <b-col
                lg="3" cols="6" class="mt-4"
            >
              <div style="display: flex;">
                <b-form-group
                    label="Talla"
                >
                  <b-form-input
                      v-model="questionnaire.height"
                      placeholder="Talla"
                  />
                </b-form-group>
                <p class="mt-3 ml-1">cm</p>
              </div>
            </b-col>
            <b-col cols="12 mt-3">
              <b-form-group label="Tipo de identifación" label-for="h-email">
                <v-select
                    v-model="type_file_select"
                    @input="changeTypeFile()"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="filesTypes"
                />
              </b-form-group>
            </b-col>
            <b-col
                lg="6" cols="12" class="mt-4"
            >
              <b-form-group v-if="!awsFront"
                            label="Identificación Frontal"
              >
                <b-form-file
                    @input="handleImage('front')"
                    v-model="front"
                    id="file_front"
                    placeholder="Suba su identificación de frente"
                    drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <b-row v-if="awsFront">
                <b-col cols="6">
                  <div align="center">
                    <a style="text-align: center" @click="dowloadFile('front')">
                      Descargar Archivo <br>
                      <feather-icon icon="ArrowDownCircleIcon" style="color: darkgreen" size="50"/>
                    </a>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div align="center">
                    <a style="text-align: center" @click="deleteFile('front')">
                      Borrar <br>
                      <feather-icon icon="TrashIcon" style="color: red" size="50"/>
                    </a>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
                lg="6" cols="12" class="mt-4"
            >
              <b-form-group
                  v-if="!awsBack"
                  label="Identificación Trasera"
              >
                <b-form-file
                    id="file_back"
                    @input="handleImage('back')"
                    placeholder="Suba su identificación trasera"
                    drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <b-row v-if="awsBack">
                <b-col cols="6">
                  <div align="center">
                    <a style="text-align: center" @click="dowloadFile('back')">
                      Descargar Archivo <br>
                      <feather-icon icon="ArrowDownCircleIcon" style="color: darkgreen" size="50"/>
                    </a>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div align="center">
                    <a style="text-align: center" @click="deleteFile('back')">
                      Borrar <br>
                      <feather-icon icon="TrashIcon" style="color: red" size="50"/>
                    </a>
                  </div>
                </b-col>
              </b-row>

            </b-col>
            <b-col cols="12" class="mt-4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        style="width: 100%"
                        variant="primary">
                Guardar
              </b-button>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-form>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import moment from 'moment';
import vSelect from "vue-select";
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BOverlay,
  BRow
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {

  name: "PatientQuestionnaire",
  props: {
    title: String,
    data_patient: Object
  },
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile, flatPickr, BFormRadio, BOverlay,vSelect
  },
  data() {
    return {
      type_file_select: {id: "", value: "Seleccione un tipo de identifación"},
      filesTypes: [
        {id: "INE", value: "INE"},
        {id: "Pasaporte", value: "Pasaporte"},
        {id: "Cédula profesional.", value: "Cédula profesional."},
        {id: "Licencia de conducir", value: "Licencia de conducir"},
        {id: "Cartilla del Servicio Militar Nacional", value: "Cartilla del Servicio Militar Nacional"},
      ],
      questionnaireId: null,
      show: false,
      front: null,
      awsFront: false,
      awsBack: false,
      back: null,
      years: 0,
      date: moment().format('YYYY-MM-DD'),
      questionnaire: {
        patient_id: null,
        blood_type: null,
        unknown_type: null,
        allergies: false,
        type_allergies: null,
        causes_allergies: null,
        reason_visit: null,
        bothersome_symptom: false,
        which_symptom: null,
        fever: false,
        date_fever: moment().format('YYYY-MM-DD'),
        studies: false,
        which_studies: null,
        recommendation_us: null,
        medical_insurance: false,
        which_medical_insurance: null,
        emergency_name: null,
        phone_emergency: null,
        relationship_patient: null,
        read_sign_notice: false,
        billing: false,
        fc: null,
        fr: null,
        ta: null,
        temperature: null,
        oxygenation: null,
        weight: null,
        height: null,
        type_file: null,
        file_front: null,
        file_back: null,
      }

    }
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.$root.$on('chargePatientQuestion', async () => {
      this.questionnaire.patient_id = this.data_patient.id;
      await this.findQuestion();

    })
  },
  methods: {
    ...mapActions('patient_questionnaire', ['findOne', 'create', 'updateQuestionRequest', 'downloadFiPatientOne', 'deleteImgFile']),
    async dowloadFile(type) {
      await this.downloadFiPatientOne({id: this.questionnaireId, type: type});
    },
    changeTypeFile() {
      this.questionnaire.type_file = this.type_file_select.id;
    },
    async fieldRequired() {
      if (!this.questionnaire.blood_type) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tipo de sangre requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.questionnaire.emergency_name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nombre de emergencias requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.questionnaire.phone_emergency) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Numero de emergencias requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.questionnaire.relationship_patient) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Parentesco requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.questionnaire.relationship_patient) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Parentesco requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.questionnaire.file_front) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Archivo frontal requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.questionnaire.type_file) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'tipo de identifación es requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return false
      }
      return true
    },
    async findQuestion() {
      const response = await this.findOne(this.questionnaire.patient_id);

      if (!response) {
        await this.createQuestion()
      } else {


        this.questionnaireId = response.id;
        delete response.id;
        delete response.createdAt;
        delete response.updatedAt;
        if (this.data_patient.birthday) {
          this.years = this.yearsCalculate(this.data_patient.birthday) + ' años ';
        }
        if (response.file_back) {
          this.awsBack = true;
        }else{
          this.awsBack = false;
        }
        if (response.file_front) {
          this.awsFront = true;
        }else{
          this.awsFront = false;
        }
        if (this.questionnaire.type_file){
          for (const a of this.filesTypes){
            if (a.id === this.questionnaire.type_file){
              this.type_file_select.id = a.id;
              this.type_file_select.value = a.value;
            }
          }
        }
        this.questionnaire = response
      }
    },
    async createQuestion() {
      await this.create(this.questionnaire);
      await this.findQuestion()
    },
    async updateQuestion() {
      if (!this.questionnaire.fever) {
        this.questionnaire.date_fever = null
      }
      const verify = await this.fieldRequired();
      if (!verify) return
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres guardar este cuestionario?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.show = true
          const response = await this.updateQuestionRequest({id: this.questionnaireId, data: this.questionnaire})
          if (response.msg) {
            this.$swal({
              icon: 'success',
              title: 'Se creo correctamente!',
              text: 'Se guardo su cuestionario correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.findQuestion()
            this.show = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al crear cuestionario con el administrador',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })


    },
    async handleImage(type) {
      let file;
      if (type === 'front') {
        file = document.querySelector(`#file_front`).files[0]
      } else {
        file = document.querySelector(`#file_back`).files[0]
      }


      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        if (type === 'front') {
          this.questionnaire.file_front = event.target.result;
        } else {
          this.questionnaire.file_back = event.target.result;
        }

      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async deleteFile(type) {


      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres eliminar este archivo??",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response =    await this.deleteImgFile({id: this.questionnaireId, type: type})

          if (response.msg) {
            this.$swal({
              icon: 'success',
              title: 'Se borro correctamente!',
              text: 'Se borro el archivo correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.findQuestion();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al borrar archivo hable con el administrador',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>